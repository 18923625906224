<script lang="ts">
	import type { PageData } from './$types';
	import Homepage from '$lib/components/Storyblok/Homepage.svelte';
	import { page } from '$app/stores';
	import EmailPopover from '$lib/components/EmailPopover.svelte';
	import { onMount } from 'svelte';
	import { useStoryblokBridge } from '@storyblok/svelte';

	export let data: PageData;

	let showNewsletter = false;

	onMount(() => {
		showNewsletter = $page.url.searchParams.has('newsletter');
		useStoryblokBridge(data.story.id, (newStory) => (data.story = newStory));
	});
</script>

<Homepage content={data.story.content} />

<EmailPopover
	bind:show={showNewsletter}
	klaviyoList="newsletter"
	titleKey="sale_popup_title"
	subtitleKey="sale_popup_subtitle"
	descriptionKey="sale_popup_description"
	emailLabel="sale_popup_email_label"
	ignoreBtnKey="sale_popup_anyway_btn"
	waitBtnKey="sale_popup_wait_btn"
	failureMessage="sale_popup_failure_message"
	successMessage="sale_popup_success_message"
/>
